import { useEffect, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useGlobal } from './services/Context';

import './styles/index.css';
import { Loaders } from './shared/Loaders';

const Login = lazy(() => import('./views/auth/Login'));
const Register = lazy(() => import('./views/auth/Register'));
const Index = lazy(() => import('./views/home/Index'));
const Career = lazy(() => import('./views/career/Career'));
const Organization = lazy(() => import('./views/organizations/Organzation'));
const Blog = lazy(() => import('./views/blog/Blog'));
const Community = lazy(() => import('./views/community/Index'));
const CommunityRegistration = lazy(() => import('./views/community/Registration'));
const Scholarship = lazy(() => import('./views/scholarship/Index'));
const Article = lazy(() => import('./views/blog/Article'));
const Testimonial = lazy(() => import('./views/career/Testimonial'));
const Upskill = lazy(() => import('./views/organizations/Upskill'));
const Contact = lazy(() => import('./views/auth/Contact'));
const Crash = lazy(() => import('./views/courses/crash/Crash'));
const Lessons = lazy(() => import('./views/courses/free/Lessons'));
const Training = lazy(() => import('./views/courses/personal-training/Training'));
const Expert = lazy(() => import('./views/courses/expert/Expert'));
// const AllCourses = lazy(() => import('./views/courses'));
const OpenCourse = lazy(() => import('./views/courses/free/OpenCourse'));
const SingleCourse = lazy(() => import('./views/courses/crash/SingleCourse'));
const ExpertLedSingle = lazy(() => import('./views/courses/expert/ExpertLedSingle'));
const RequestTutor = lazy(() => import('./views/courses/personal-training/RequestTutor'));
const PrivateRoute = lazy(() => import('./services/PrivateRoute'));
const ForgotPassword = lazy(() => import('./views/auth/ForgotPassword'));
const PasswordReset = lazy(() => import('./views/auth/PasswordReset'));
const PasswordUpdate = lazy(() => import('./views/auth/PasswordUpdate'));
const Downtime = lazy(() => import('./views/downtime'));
const GetStarted = lazy(() => import('./views/get-started'));
const Roadmap = lazy(() => import('./views/roadmap'));
const Faq = lazy(() => import('./views/faq'));
const FaqDetails = lazy(() => import('./views/faq/details'));

const WOW = require('wowjs')

function App() {
  const { setmsg, msg } = useGlobal()

  window.addEventListener('offline', () => {
    setmsg("Hello Friend,  You are currently offline please do connect to a stable network to continue ")
  })
  window.addEventListener('online', () => {
    setmsg("Welcome back online")
    setTimeout(() => {
      setmsg("")
    }, 3000);
  })
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <div className="App">
      <Suspense fallback={<Loaders />}>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/home" exact component={Index} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/password-reset" exact component={PasswordReset} />
          <Route path="/update-password" exact component={PasswordUpdate} />
          <Route path="/organization/school" exact component={Organization} />
          <Route path="/organization/upskill" exact component={Upskill} />
          <Route path="/career/opportunities" exact component={Career} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/faq" exact component={Faq} />
          <Route path="/faq/:id" exact component={FaqDetails} />
          <Route path="/community" exact component={Community} />
          <Route path="/community/register" exact component={CommunityRegistration} />
          <Route path="/blog/:id" exact component={Article} />
          <Route path="/career/testimonial" exact component={Testimonial} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/get-started" exact component={GetStarted} />
          <Route path="/roadmap" exact component={Roadmap} />
          <Route path="/courses" exact component={Expert} />
          <Route path="/courses/crash-courses" exact component={Crash} />
          <PrivateRoute path="/courses/free-lesson" exact component={Lessons} />
          <Route path="/personaltraining" exact component={Training} />
          <Route path="/courses/personal-training" exact component={Training} />
          <Route path="/courses/expert-led" exact component={Expert} />
          <Route path="/courses/crash-course-info/:id" exact component={SingleCourse} />
          <Route path="/courses/expert-led-course-info/:id" exact component={ExpertLedSingle} />
          <Route path="/courses/request-tutor/:id" exact component={RequestTutor} />
          <Route path="/scholarship" exact component={Scholarship} />
          <Route path="/work-in-progress" exact component={Downtime} />
          <PrivateRoute path="/courses/free-lesson/:category/lesson/:id" component={OpenCourse} />
          <PrivateRoute path="/courses/free-lesson/:category" component={OpenCourse} />

          <Redirect from="**" to="/home" />
          <Redirect from="/career" to="/career/opportunities" />
          <Redirect from="/organization" to="/organization/school" />

        </Switch>
      </Suspense>
      {
        msg && (
          <div className="err-msg" >
            <p>{msg}</p>
          </div>
        )
      }
    </div>
  );
}

export default App;

