import React from 'react'
import Pulse from '../assets/images/pulselader.svg'
import { useGlobal } from '../services/Context'
export const Loaders = () => {
    const { isLoadingMain } = useGlobal()
    return (
        <div style={{ display: isLoadingMain ? 'block' : 'none', position:'fixed', top:'0%', right:'0%',bottom:'0%', left:'0%', backgroundColor:'rgba(0, 0, 0, 0.774)', zIndex:99999999999 }}>
        <span style={{ position:'fixed', top:'50%', right:'50%',transform:'translate(50% , -50%)' }}>
            <img src={Pulse} alt="loading..." />
        </span>
        </div>
    )
}

export const ButtonLoader = () => {
    const { isLoading } = useGlobal()
    return (
        <span style={{ display: isLoading ? 'inline-block' : 'none' }}>
            <img src={Pulse} alt="loading..." style={{ width: '50px', height: '30px' }} />
        </span>
    )
}


