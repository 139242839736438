import { initializeApp } from "firebase/app";
// import app from 'firebase/app'
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'


// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };z
const firebaseConfig = {
    apiKey: "AIzaSyCYXcsIEExolQ_PNmD3gXekBTB4DG_qDfE",
    authDomain: "cholatrek-institute.firebaseapp.com",
    projectId: "cholatrek-institute",
    storageBucket: "cholatrek-institute.appspot.com",
    messagingSenderId: "614597963829",
    appId: "1:614597963829:web:cdd5f6f783ab359640817c"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const Firebase = app.initializeApp(firebaseConfig);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const fbprovider = new FacebookAuthProvider();
