import { auth, provider, fbprovider } from './Firebase';
import { signInWithPopup } from 'firebase/auth'
// const baseurl = process.env.REACT_APP_BASEURL
const baseurl = "https://api.cholatrek.io/"
// const baseurl = "https://cholatrek.herokuapp.com/"
let loggedUser = localStorage.getItem('us') || JSON.stringify({ message: '', data: {}, token: '' });
let data = JSON.parse(loggedUser);
let headers = {
    "Content-Type": "application/json",     
    cholatrek_access_token: `${data?.token}`,
};
export const getRequestExternal = async (url: string) => {
    const request = await fetch(`${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
    return request.json()
}
export const getRequest = async (url: string) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "GET",
        headers: headers,
    })
    return request.json()
}
export const postRequest = async (url: string, body: any) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
    return request.json()
}

export const patchRequest = async (url: string, body: any) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(body)
    })
    return request.json()
}

export const getRequestbody = async (url: string, params: any) => {
    const requestOptions: any = {
        method: "GET",
        boody: params,
        headers: headers,
        redirect: "follow"
    }
    const request = await fetch(`${baseurl}${url}`, requestOptions)
    return request.json()
}

// // regular sign up
// const register = async (body: any) => {
//     const url = "user/signup"
//     await postRequest(url, body)
//         .then(response => {
//             if (response.ok) { 
//                 localStorage.setItem("us", JSON.stringify(response.data))
//                 console.log(response)
//             }
//         })
// }
//regular login
// const login = async (body: any) => {
//     const url = "user/signin"
//     await postRequest(url, body)
//         .then(response => {
//                 localStorage.setItem("us", JSON.stringify(response.data))
//                 window.location.replace('/home')
//         })
// }
const signInGoogleUser = () => {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // // const token = credential.accessToken;
            // // The signed-in user info.
            // const user = result.user;
            // ...
        }).catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.email;
            // // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
}
const signInFacebookUser = () => {
    signInWithPopup(auth, fbprovider)
        .then((result) => {
            // The signed-in user info.
            // const user = result.user;

            // // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // const credential = FacebookAuthProvider.credentialFromResult(result);
            // const accessToken = credential.accessToken;

            // ...
        })
        .catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.email;
            // // The AuthCredential type that was used.
            // const credential = FacebookAuthProvider.credentialFromError(error);

            // ...
        });
}

export const requests = {
    // register,
    // login,
    signInFacebookUser,
    signInGoogleUser
}
