import React, { useContext, useEffect, useState } from 'react'
import { postRequest, getRequest, getRequestExternal, patchRequest } from './api'
import { useHistory } from 'react-router-dom';
import { feedback } from './feedback';
import { auth, provider, fbprovider } from './Firebase';
import { signInWithPopup, } from 'firebase/auth'
import Cookies from 'universal-cookie';
// import { closePaymentModal, useFlutterwave } from 'react-flutterwave';
const GlobalContext = React.createContext<any | null>(null)

export const useGlobal = () => {
    const globalContext: any = useContext(GlobalContext)
    return globalContext
}



const Context = ({ children }: any) => {
    // get user from local storage
    const getLogged: any = localStorage.getItem('us');
    const logged = JSON.parse(getLogged) || null
    const getdate = localStorage.getItem('date')
    // const getrate: any = localStorage.getItem('exchangerate')
    const rates: any = null
    // getrate && getrate !== undefined && getrate !== null ? JSON?.parse(getrate) : 
    const [currentUser, setcurrentUser]: any = useState(logged)
    const [isLoading, setisLoading] = useState<boolean | { status: boolean; elem: string }>(false)
    const [isLoadingMain, setisLoadingMain] = useState(false)
    const [openModal, setopenModal] = useState(false)
    const [msg, setmsg]: any = useState(null)
    const [landingCourses, setlandingCourses]: any = useState(null)
    const [landingPage, setlandingPage]: any = useState(null)
    const [blog, setblog]: any = useState(null)
    const [blogs, setblogs]: any = useState(null)
    const [courseinstructor]: any = useState(null)
    const [course, setcourse]: any = useState(null)
    const [courses, setcourses]: any = useState(null)
    const [categories, setCategories]: any = useState(null)
    const [freeCategories, setFreeCategories]: any = useState(null)
    const [testimonials, settestimonials]: any = useState(null)
    const [lesson, setlesson]: any = useState(null)
    const [resetPasswordDetails, setresetPasswordDetails]: any = useState(null)
    const [couponcode, setcouponcode]: any = useState(null)
    const [paymentdata, setpaymentdata]: any = useState(null)
    const [exchangerate, setexchangerate]: any = useState(null)
    const [searchSuggestions, setsearchSuggestions]: any = useState(null)

    const history: any = useHistory()

    const cookies = new Cookies()
    // const user = auth.currentUser;


    useEffect(() => {
        setcurrentUser(logged)

        cookies.set('token', `${currentUser?.token}`, { domain: "cholatrek.io" })

        if (getdate !== new Date().toDateString() || !rates) {
            localStorage.setItem('date', new Date().toDateString());
            getExchangeRates()
        }

        setexchangerate(rates)
        // console.log(exchangerate)
        // if (user !== null) {
        //     const userDetails = {
        //         name: user.displayName,
        //         photoURL: user.photoURL,
        //         email: user.email,
        //         phoneNumber: user.phoneNumber,
        //         uid: user.uid,

        //         emailVerified: user.emailVerified,
        //     }
        //     setcurrentUser(userDetails)
        // }
    }, [])

    // changes loader state and visibility
    const startLoading = (id: any) => {
        setmsg("")
        if (id === 0) {
            setisLoadingMain(true)
        } else {
            setisLoading(true)
        }
    }
    const endLoading = (id: any) => {
        if (id === 0) {
            setisLoadingMain(false)

        } else {
            setisLoading(false)

        }
    }

    // changes modal visibility
    const changeModalState = () => {
        setopenModal(!openModal)
    }

    const resetMsg = () => {
        setTimeout(() => {
            setmsg(null)
        }, 7000);
    }

    const truncateString = (str: string, num: number) => {
        if (str?.length <= num) {
            return str
        } else {
            return str?.slice(0, num) + '...'
        }
    }


    // landing page
    const getLandingPage = async () => {
        startLoading(0)
        const url = `admin/landing`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setlandingPage(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    // landing page courses
    const getLandingCourses = async (params: string) => {
        startLoading(0)
        const url = `courses/landing?${params}`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setlandingCourses(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // all testimonials
    const getTestimonials = async () => {
        startLoading(0)
        const url = `admin/testimonial/all`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    settestimonials(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    // subscrie to newsletter
    const subscribe = async (body: any) => {
        startLoading(1)
        const url = "subscribe"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // techmvers community
    const community = async (body: any) => {
        startLoading(1)
        const url = "techmovers"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    //apply for scholarship
    const scholarship = async (body: any) => {
        setmsg('scholarship application is closed')
        // startLoading(1)
        // const url = "scholarship"
        // await postRequest(url, body)
        //     .then(response => {
        //         endLoading(1)
        //         if (response.status) {
        //             setmsg(feedbackMsg(response.message) || null)
        //             setTimeout(() => {
        //                 setmsg(null)
        //             }, 10000);
        //         }
        //         else {
        //             se
        // setmsg(feedbackMsg(response.error) || null)
        //             setTimeout(() => {
        //                 setmsg(null)
        //             }, 10000);
        //         }

        //     }).catch((res) => {
        //         endLoading(1)
        //         setmsg("something went wrong, check your internet connection")
        //         setTimeout(() => {
        //             setmsg(null)
        //         }, 10000);
        //     })
    }
    //contact support
    const support = async (body: any) => {
        startLoading(1)
        const url = "support"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    // regular sign up
    const register = async (body: any) => {
        startLoading(1)
        const url = "user/signup"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    localStorage.setItem("us", JSON.stringify(response))
                    window.location.replace('/home')
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // regular sign up
    const googleRegister = async (body: any) => {
        const url = "user/signup/google"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    localStorage.setItem("us", JSON.stringify(response))
                    window.location.replace('/home')
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const signUpGoogleUser = (name: any) => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                const user = result.user;
                const body = {
                    firstName: name.firstName,
                    lastName: name.lastName,
                    email: user.email,
                    userToken: user.uid
                }
                googleRegister(body)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // const email = error.email;
                // const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    // regular sign in
    const login = async (body: any) => {
        const url = "user/signin"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    localStorage.setItem("us", JSON.stringify(response))
                    window.location.replace('/home')
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            })
    }

    const googleLogin = async (body: any) => {
        const url = "user/signin/google"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    localStorage.setItem("us", JSON.stringify(response))
                    window.location.replace('/home')
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const signInGoogleUser = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                const user = result.user;
                const body = {
                    email: user.email,
                    userToken: user.uid
                }
                googleLogin(body)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // const email = error.email;
                // const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }
    const signInFacebookUser = () => {
        signInWithPopup(auth, fbprovider)
            .then((result) => {
                // // The signed-in user info.
                // const user = result.user;

                // // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                // const credential = FacebookAuthProvider.credentialFromResult(result);
                // // const accessToken = credential.accessToken;

                // // ...
            })
            .catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.email;
                // // The AuthCredential type that was used.
                // const credential = FacebookAuthProvider.credentialFromError(error);

                // ...
            });
    }

    // reset password
    const passwordRecovery = async (body: any) => {
        startLoading(1)
        const url = "user/password_recovery"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setresetPasswordDetails(response)
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                    history.push("/password-reset")

                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // reset password
    const resetPassword = async (body: any) => {
        startLoading(1)
        const url = "user/password_reset"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    // update password
    const updatePassword = async (body: any) => {
        startLoading(1)
        const url = "user/update_password"
        await patchRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // landing all courses
    const getCourses = async (params: string) => {
        startLoading(0)
        const url = `courses?${params}`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setcourses(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // landing page courses
    const getACourse = async (id: string) => {
        startLoading(0)
        const url = `courses/${id}`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setcourse(response.data)
                    // getACourseInstructor(response.data.instructors[0])
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // landing course insructor
    // const getACourseInstructor = async (id: string) => {
    //     startLoading(0)
    //     const url = `admin/instructor/${id}`
    //     await getRequest(url)
    //         .then(response => {
    //             endLoading(0)
    //             if (response.status) {
    //                 setcourseinstructor(response.data)
    //             }
    //             else {
    //                 setmsg(feedbackMsg(response.error) || null)
    //                 resetMsg()
    //             }

    //         }).catch((res) => {
    //             endLoading(0)
    //             setmsg("something went wrong, check your internet connection")
    //             resetMsg()
    //         })
    // }

    // all blog rticles
    const getBlogs = async () => {
        startLoading(0)
        const url = `admin/blog/all`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setblogs(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    // landing page courses
    const getABlog = async (id: string) => {
        startLoading(0)
        const url = `admin/blog/${id}`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setblog(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const blogComment = async (body: any, id: any) => {
        startLoading(1)
        const url = `admin/blog/${id}`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const bookConsultation = async (body: any) => {
        startLoading(1)
        const url = `booking/consultation`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const bookTutor = async (body: any) => {
        startLoading(1)
        const url = `booking/tutor`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const validateCoupon = async (code: string) => {
        startLoading(0)
        const url = `user/coupon/${code}`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setcouponcode(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    const getCategories = async () => {
        startLoading(0)
        const url = `courses/categories`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setCategories(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    const getFreeCategories = async () => {
        startLoading(0)
        const url = `courses/categories/free`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setFreeCategories(response.data)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const courseEnroll = async (body: any, id: any) => {
        startLoading(1)
        const url = `user/coupon/enroll/${id}`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setpaymentdata(response.data)
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    const courseEnrollFree = async (body: any, id: any) => {
        startLoading(1)
        const url = `user/coupon/enroll/${id}`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                    window.location.replace(`https://classroom.cholatrek.io/${currentUser?.token}`)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                    if (response?.error === "ENROLLMENT_ERROR") {
                        window.location.replace(`https://classroom.cholatrek.io/${currentUser?.token}`)
                    }
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    const installmentCourseEnroll = async (body: any, id: any) => {
        startLoading(1)
        const url = `user/coupon/enroll/${id}/installment`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setpaymentdata(response.data)
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                    window.location.replace(`https://classroom.cholatrek.io/${currentUser}`)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }
                // history.push('/home')
            })
            .catch(res => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    // search for a course
    const courseSearch = async (params: String) => {
        startLoading(1)
        const url = `search?${params}`
        await getRequest(url)
            .then((res) => {
                endLoading(1)
                if (res.status) {
                    setsearchSuggestions(res.data)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(res.error) || null)
                    resetMsg()
                }
            })
            .catch((err) => {

            })
    }
    const feedbackMsg = (label: any) => {
        let res;
        Object.entries(feedback).forEach(([key, value]) => {
            if (key === label) {
                res = value
            }
        })
        return res
    }
    // free lessons review
    const postLessonReview = async (body: any) => {
        startLoading(1)
        const url = "user/course/free/review"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    setmsg(feedbackMsg(response.message) || null)
                    resetMsg()
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(1)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }

    const getMediumArticle = async () => {
        startLoading(0)
        const url = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@africandevelopr`
        await getRequestExternal(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setlesson(response)
                }
                else {
                    setmsg(feedbackMsg(response.error) || null)
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("something went wrong, check your internet connection")
                resetMsg()
            })
    }
    const getExchangeRates = async () => {
        startLoading(0)
        const url = `https://api.currencyapi.com/v3/latest?apikey=YgW5uvQB7qb7pYsB92Ve7mcDtiH9EczSAYOeHxYJ`
        await getRequestExternal(url)
            .then(response => {
                endLoading(0)
                if (response) {
                    // setexchangerate(response?.data)
                    setexchangerate(null)
                    // localStorage.setItem('exchangerate', JSON.stringify(response?.data))
                }
                else {
                    setmsg("error fetching exchange rates")
                    resetMsg()
                }

            }).catch((res) => {
                endLoading(0)
                setmsg("error fetching exchange rates")
                resetMsg()
            })
    }

    const value: any = {
        setpaymentdata,
        truncateString,
        setmsg,
        register,
        login,
        resetPassword,
        signUpGoogleUser,
        signInGoogleUser,
        signInFacebookUser,
        startLoading,
        changeModalState,
        support,
        getLandingCourses,
        getTestimonials,
        bookConsultation,
        passwordRecovery,
        subscribe,
        getMediumArticle,
        community,
        getCourses,
        getACourse,
        searchSuggestions,
        courseSearch,
        getLandingPage,
        getBlogs,
        getABlog,
        blogComment,
        courseEnroll,
        installmentCourseEnroll,
        validateCoupon,
        getExchangeRates,
        updatePassword,
        scholarship,
        getCategories,
        getFreeCategories,
        bookTutor,
        postLessonReview,
        courseEnrollFree,
        categories,
        freeCategories,
        exchangerate,
        paymentdata,
        couponcode,
        lesson,
        blog,
        blogs,
        landingPage,
        course,
        courses,
        testimonials,
        landingCourses,
        courseinstructor,
        resetPasswordDetails,
        isLoading,
        isLoadingMain,
        openModal,
        feedback,
        currentUser,
        msg,

    }

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}

export default Context
